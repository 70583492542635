<template>
  <div id="deviceList">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>停车记录管理</el-breadcrumb-item>
      <el-breadcrumb-item>出入口列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
            </el-input>
          </li>
          <li>
            <span class="label">选择停车场:</span>
            <el-select
              v-model="filtrate.parkingLotId"
              filterable
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in parkingLotList"
                :key="item.parkingLotId"
                :label="item.parkingLotName"
                :value="item.parkingLotId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <el-button type="primary" size="medium" @click="getList(1)"
              >查询</el-button
            >
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="addPassageway"
              type="primary"
              @click="addPassageway"
              v-if="buttonList.includes('passagewayList-addPassageway')"
              ><i class="iconfont icon-jiahao"></i> 新建出入口</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="channelName" label="出入口名称" align="center">
        </el-table-column>
        <el-table-column prop="channelType" label="出入口类型" align="center">
        </el-table-column>
        <el-table-column
          prop="channelAddress"
          label="停车场位置"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="parkingLotName"
          label="停车场名称"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="parkingAisle" label="过场道闸" align="center">
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="goToDetail(scope.row.channelId)"
              v-if="buttonList.includes('passagewayList-detail')"
              >查看详情</el-button
            >
            <el-button
              type="text"
              @click="checkMonitor(scope.row)"
              v-if="buttonList.includes('passagewayList-checkMonitor')"
              >查看监控</el-button
            >
            <el-button
              type="text"
              @click="opening(scope.row.channelId)"
              v-if="buttonList.includes('passagewayList-openBtn')"
              >开闸</el-button
            >
            <el-button
              type="text"
              @click="del(scope.row.channelId)"
              v-if="buttonList.includes('passagewayList-delBtn')"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>

    <add-passageway-dialog ref="addPassagewayDialog" :getList="getList" />
    <video-dialog ref="videoDialog" />
  </div>
</template>
<script>
export default {
  components: {
    addPassagewayDialog: () => import("./addPassagewayDialog.vue"),
    videoDialog: () => import("@/components/videoDialog.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
        parkingLotId: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    checkMonitor(row) {
      this.$refs.videoDialog.channelId = row.channelId;
      this.$refs.videoDialog.title = row.channelName;
      this.$refs.videoDialog.show = true;
    },
    del(channelId) {
      this.$confirm("此操作将永久删除该出入口, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.delete(`/channel/delete/${channelId}`);
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    addPassageway() {
      this.$refs.addPassagewayDialog.show = true;
    },
    opening(channelId) {
      this.$msgbox({
        title: "提示",
        message: "此操作将完成线上开闸, 是否继续?",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            try {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "处理中...";
              let res = await this.$http.post("/channel/manual/opening", {
                channelId,
              });
              if (res.code === 0) {
                if (res.data) {
                  this.$message.success("开闸成功");
                } else {
                  this.$message.error("开闸失败");
                }
              } else {
                this.$message.error(res.msg);
              }
            } catch (err) {
              this.$message.error(err);
            } finally {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }
          } else {
            done();
          }
        },
      });
    },
    reset() {
      this.filtrate.keyword = "";
      this.filtrate.parkingLotId = "";
      this.getList(1);
    },
    goToDetail(channelId) {
      this.$router.push({
        name: "passagewayDetail",
        params: { id: channelId },
      });
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/channel/list", {
          pageNum: page,
          pageSize: this.table.pageSize,
          keyword: this.filtrate.keyword,
          parkingLotId: this.filtrate.parkingLotId,
        });
        if (res.code === 0) {
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#deviceList {
  .table-container {
    .table-filtrate {
      ul {
        li {
          .addPassageway {
            background-color: #ff9747;
            border-color: #ff9747;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
